import { FC } from 'react';
import { NotEligibleContainer } from '../css';

interface INotEligibleProps {
	data: string[];
}

const NotEligible: FC<INotEligibleProps> = ({ data }) => {
	return (
		<NotEligibleContainer>
			<img src='/img/wallet-v2/warning.webp' alt='' className='warning' />
			<div className='divider' />
			<div className='content_'>
				Unfortunately, you are not eligible for the airdrop because of:
				{data.map(i => (
					<div key={i} className='box_'>
						{i}
					</div>
				))}
			</div>
		</NotEligibleContainer>
	);
};

export default NotEligible;
