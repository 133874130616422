import { IGetAllPractice } from 'api/v1/practice';
import { useEffect, useState } from 'react';
import { PracticeCardContainer } from '../css';
import PracticeModal from './practice-modal';
import { useGetAllPracticeQuery } from 'store/query';
import Loader from 'shared/components/loader';
import numberUtils from 'shared/utils/number';
import { flush, injectGlobal } from '@emotion/css';

const PracticeCard = () => {
	const [modalStatus, setModalStatus] = useState<{ status: boolean }>({ status: false });
	const [selectedPractice, setSelectedPractice] = useState<IGetAllPractice['practiceList'][0]>();
	const { data, isLoading } = useGetAllPracticeQuery();
	useEffect(() => {
		injectGlobal`
			body,nav{
				background: #00001c;
			}
		`;
		return () => {
			flush();
		};
	}, []);
	return !isLoading && data ? (
		<PracticeCardContainer>
			{data.practiceList.map((item, index) => (
				<div
					className={`card ${item.nextPracticeTime * 1000 > Date.now() ? 'disabled' : ''}`}
					key={`${index}-card-practice`}
					onClick={() => {
						if (item.nextPracticeTime * 1000 > Date.now()) return;
						setSelectedPractice(item);
						setModalStatus({ status: true });
					}}
				>
					<img src={item.icon} alt='' />
					<div className='card_content'>
						<h4>{item.title}</h4>
						<p>Mindfulness is the art of being present</p>
						<strong>
							<p>
								Reward: 💎 +<span>{numberUtils.formatPriceWithDecimals(item.profit)}</span>{' '}
							</p>
							<div className='day'>
								<p>{item.dayOfRound}/7 </p>
							</div>
						</strong>

						<div className='progress_bar'>
							{Array.from({ length: 7 }).map((_, index) => (
								<div key={index} className={`progress_segment ${index < item.dayOfRound ? 'filled' : ''}`}></div>
							))}
						</div>
						{item.dayOfRound >= 6 && (
							<div className='six_day'>
								Extra Reward :{' '}
								<span>
									<img src='/img/dollar.webp' alt='' /> +100,000
								</span>
							</div>
						)}
					</div>
				</div>
			))}

			{modalStatus.status && selectedPractice && (
				<PracticeModal data={selectedPractice} setStatus={status => setModalStatus({ status })} status={modalStatus.status} />
			)}
		</PracticeCardContainer>
	) : (
		<Loader />
	);
};
export default PracticeCard;
