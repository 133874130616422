import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const WalletContainer = styled.div(
	() => css`
		position: relative;
		padding-bottom: 120px;
		background: #002554;
		/* height: 100vh; */
		header {
			position: relative;

			.header_bg {
				position: absolute;
				width: 100%;
				top: 0;
				right: 0;
				z-index: 1;
			}
			.header_hero {
				position: absolute;
				top: 30px;
				z-index: 3;
				width: 60vw;
			}
			.header_overlay {
				position: absolute;
				top: 35vw;
				width: 100%;
				height: 30vw;
				z-index: 2;
				background: linear-gradient(to bottom, transparent 0%, #002554 35%, #002554 100%);
			}
		}

		.content {
			position: relative;
			z-index: 2;
			padding-top: 140px;

			@media (min-width: 390px) {
				padding-top: 170px;
			}

			.timer_title {
				font-size: 13.5px;
				font-weight: 400;
				line-height: 16px;
				text-align: center;
				color: #fff;
				margin-bottom: 8px;
			}
			.content_timer {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				padding: 21px;
				position: relative;
				top: -30px;
			}
		}
		.btn {
			width: 100%;
			justify-self: center;

			margin-top: 32px;
		}
		h4 {
			margin-top: 25px;
			margin-bottom: 5px;
			color: #fff;
			text-align: center;
			font-size: 20px;
			font-weight: bold;
			line-height: 26px;
			text-align: center;
		}
		.red_ {
			background: #b40609;
			border: 1px solid #f5c21d;
			border-radius: 10px;
			padding: 13px;
			width: 92%;
			margin: auto;
			margin-top: 16px;
			font-size: 14px;
			font-weight: 600;
			line-height: 16px;
			text-align: center;
			color: #fff;
			strong{
				color:#f5c21d;
			}
		}
		.dic_ {
			display: flex;
			background: #004b94;
			border-radius: 16px;
			width: 92%;
			margin: auto;

			.logo {
				width: 58px;
				padding: 8px;
			}
			.copy_div {
				width: 100%;
				background: #1068b9;
				border-radius: 16px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: auto;
				left: 0;
				right: 0;
				position: relative;
				border: 1px solid #f5c21d;
				p {
					text-align: center;
					max-width: 95%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					padding-inline-start: 8px;
					text-align: center;
					color: #fff;
					width: inherit;
				}
				.copy_icon {
					padding: 16px 22px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #f5c907;
					border-radius: 16px;
					svg {
						color: #000;
					}
				}
			}
		}
		.light_circle {
			background: radial-gradient(43.04% 43.04% at 50% 50%, #07549e 0%, rgba(1, 39, 86, 0) 100%);
			position: fixed;
			width: 646px;
			height: 335px;
			left: -68px;
			top: 50vh;
		}
	`,
);
