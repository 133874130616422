// import { useEffect, useState } from 'react';
import { WalletContainer } from './css';
import { Helmet } from 'react-helmet';
import { List, NotEligible } from './components';
import { CopyOutline } from 'shared/components/icons';
import toast from 'react-hot-toast';
import { useAirdropIsEligibleQuery, useAllExchangesQuery } from 'store/query';

const Wallet = () => {
	const { data: isEligible } = useAirdropIsEligibleQuery();
	const { data: allExchanges } = useAllExchangesQuery();
	const logo = allExchanges?.exchanges.filter(i => i.name === allExchanges.user.exchange)[0];

	const onCopyClickHandler = () => {
		if (allExchanges?.user.uid !== null && allExchanges?.user.uid !== undefined) {
			navigator.clipboard.writeText(allExchanges?.user.uid).then(() => {
				toast.success('Copied to clipboard successfully!');
			});
		}
	};
	const onCopyClickHandler_ = () => {
		if (allExchanges?.user.walletAddress !== null && allExchanges?.user.walletAddress !== undefined) {
			navigator.clipboard.writeText(allExchanges?.user.walletAddress).then(() => {
				toast.success('Copied to clipboard successfully!');
			});
		}
	};
	return (
		<WalletContainer>
			<Helmet>
				<title>PinEye | Wallet</title>
			</Helmet>
			<header>
				<img src='/img/wallet-v2/header-bg.webp' alt='' className='header_bg' />
				<img src='/img/wallet-v2/header-hero.webp' alt='' className='header_hero' />

				<div className='header_overlay' />
			</header>

			<div className='content'>
				<div className='content_timer'>
					{/* <div className='timer_container'>
						<p className='timer_title'>Connect Exchange</p>
						<CountDown date={1738670400000} />
					</div> */}
				</div>
			</div>
			
			{isEligible && isEligible.isEligible && (
				<>
					<List />
					<div className='red_'>
				We are currently working on <strong>distributing</strong> through exchanges ....
				<br />
				<br />
				Thank you for your patience!
			</div>
					<h4>UID:</h4>
					<div className='dic_'>
						{allExchanges?.user.exchange && <img src={logo?.iconUrl} alt={logo?.name} className='logo' />}
						<div className='copy_div'>
							<p>{allExchanges?.user.uid ? allExchanges.user.uid : 'No uis address'}</p>
							<div className='copy_icon' onClick={() => onCopyClickHandler()}>
								<CopyOutline />
							</div>
						</div>
					</div>
					<h4>Wallet Address:</h4>
					<div className='dic_'>
						{allExchanges?.user.exchange && <img src={logo?.iconUrl} alt={logo?.name} className='logo' />}
						<div className='copy_div'>
							<p>{allExchanges?.user.walletAddress ? allExchanges.user.walletAddress : 'No wallet address'}</p>
							<div className='copy_icon' onClick={() => onCopyClickHandler_()}>
								<CopyOutline />
							</div>
						</div>
					</div>
				</>
			)}
			{isEligible && !isEligible.isEligible && <NotEligible data={isEligible.cause} />}
		</WalletContainer>
	);
};
export default Wallet;
