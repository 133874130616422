import { useAirdropGetFinalQuery } from 'store/query';
import { ListContainer } from '../css';
import Loader from 'shared/components/loader';
import numberUtils from 'shared/utils/number';
  
const List = () => {
	const { data: airdropData } = useAirdropGetFinalQuery();
 	const data = [
		{
			icon: '/img/wallet-v2/coin.webp',
			title: 'Total $PINEYE',
			value: (
				<>
					<img src='/img/dollar.webp' alt='' /> {numberUtils.formatPriceWithDecimals(airdropData?.totalPineye ?? 0)}
				</>
			),
		},
		{
			icon: '/img/wallet-v2/list.webp',
			title: 'Regulated Balance',
			value: (
				<>
					<img src='/img/dollar.webp' alt='' /> {numberUtils.formatPriceWithDecimals(airdropData?.regulatedBalance ?? 0)}
				</>
			),
		},
		// {
		// 	icon: '/img/wallet-v2/bag.webp',
		// 	title: 'Extra Reward',
		// 	value: (
		// 		<>
		// 			<img src='/img/dollar.webp' alt='' /> {numberUtils.formatPriceWithDecimals(airdropData?.extraReward ?? 0)}
		// 		</>
		// 	),
		// },
		{
			icon: '/img/wallet-v2/vip.webp',
			title: 'VIP Reward',
			value: (
				<>
					<img src='/img/dollar.webp' alt='' /> {numberUtils.formatPriceWithDecimals(airdropData?.vipReward ?? 0)}
				</>
			),
		},
	];

	return (
		<ListContainer>
			{!airdropData && <Loader />}
			{airdropData && (
				<div className='list'>
					{data.map(item => (
						<div className='list_item'>
							<img className='icon' src={item.icon} alt='' />
							<p className='list_title'>{item.title} </p>
							<div className='coin'>{item.value}</div>
						</div>
					))}
				</div>
			)}
			{/* <ButtonNew className='btn' variant='thirty' onClick={() => navigate('/exchange')}>
				Choose Exchange
			</ButtonNew> */}
		</ListContainer>
	);
};

export default List;
