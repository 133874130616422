import { configureStore } from '@reduxjs/toolkit';
import Profile from './reducers/profile';

import Practice from './reducers/practice';
import socket from './reducers/socket';

import {
	PranaSliceApi,
	ReferralRewardSliceApi,
	FriendSliceApi,
	DailyRewardSliceApi,
	GameSliceApi,
	WalletSliceApi,
	// ReferralBalanceSliceApi,
	RewardHistorySliceApi,
	AcademySliceApi,
	AvatarSliceApi,
	AssetsSliceApi,
	ExchangeSliceApi,
} from './query';
import SocialSliceApi from './query/social';

export default configureStore({
	reducer: {
		profile: Profile,

		practice: Practice,
		socket,
		[PranaSliceApi.reducerPath]: PranaSliceApi.reducer,
		[ReferralRewardSliceApi.reducerPath]: ReferralRewardSliceApi.reducer,
		[FriendSliceApi.reducerPath]: FriendSliceApi.reducer,
		[DailyRewardSliceApi.reducerPath]: DailyRewardSliceApi.reducer,
		[SocialSliceApi.reducerPath]: SocialSliceApi.reducer,
		[GameSliceApi.reducerPath]: GameSliceApi.reducer,
		[WalletSliceApi.reducerPath]: WalletSliceApi.reducer,
		[RewardHistorySliceApi.reducerPath]: RewardHistorySliceApi.reducer,
		[AcademySliceApi.reducerPath]: AcademySliceApi.reducer,
		[AvatarSliceApi.reducerPath]: AvatarSliceApi.reducer,
		[AssetsSliceApi.reducerPath]: AssetsSliceApi.reducer,
		[ExchangeSliceApi.reducerPath]: ExchangeSliceApi.reducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat([
			PranaSliceApi.middleware,
			FriendSliceApi.middleware,
			ReferralRewardSliceApi.middleware,
			DailyRewardSliceApi.middleware,
			SocialSliceApi.middleware,
			GameSliceApi.middleware,
			WalletSliceApi.middleware,
			RewardHistorySliceApi.middleware,
			AcademySliceApi.middleware,
			AvatarSliceApi.middleware,
			AssetsSliceApi.middleware,
			ExchangeSliceApi.middleware,
		]),
});
